<template>
  <TsNavbar
    :NavBarCustomClasses="NavBarCustomClasses"
    @updateLang="updateLanguage"
  />
  <main :class="['ts-wrapper', { 'signup-bg': isSignup }]">
    <RouterView @data-navBarCustomClasses="receiveDataFromChild" />
  </main>
  <TsFooter />
</template>

<script>
import TsNavbar from "./TsNavbar.vue";
import TsFooter from "./TsFooter.vue";

export default {
  name: "App",
  components: {
    TsNavbar,
    TsFooter,
  },
  data() {
    return {
      NavBarCustomClasses: "",
      isSignup: false, // New property to manage conditional class
    };
  },
  watch: {
    // Watch the route changes
    $route(to) {
      this.checkSignupRoute(to.path);
    },
  },
  mounted() {
    // On component mount, check the initial route
    this.checkSignupRoute(this.$route.path);
  },
  methods: {
    receiveDataFromChild(data) {
      this.NavBarCustomClasses = data;
    },
    // Method to check the route and update the isSignup state
    checkSignupRoute(path) {
      this.isSignup = path.endsWith("/brand-signups");
    },
  },
};
</script>

<style lang="scss">
.ts-wrapper {
  &.signup-bg {
    background-color: #f3f3f3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 768px) {
      background: #ffffff;
    }
  }
}
</style>
