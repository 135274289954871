import { createRouter, createWebHistory } from "vue-router";

// Pages
import Blog from "../Pages/Blog.vue";
import BlogPost from "../Pages/BlogPost.vue";
import BrandFaq from "../Pages/BrandFaq.vue";
import BrandTc from "../Pages/BrandTc.vue";
import Brands from "../Pages/Brands.vue";
import BrandsDemoRequest from "../Pages/BrandsDemoRequest.vue";
import BrandsSignup from "../Pages/BrandsSignup.vue";
import Career from "../Pages/Career.vue";
import Contact from "../Pages/Contact.vue";
import Creators from "../Pages/Creators.vue";
import CreatorFaq from "../Pages/CreatorFaq.vue";
import CreatorSignup from "../Pages/CreatorSignup.vue";
import CreatorTc from "../Pages/CreatorTc.vue";
import Home from "../Pages/Home.vue";
import NotFound from "../Pages/NotFound.vue";
import Overview from "../Pages/Overview.vue";
import PrivacyTc from "../Pages/PrivacyTc.vue";
import Subscriptions from "../Pages/Subscriptions.vue";
import Team from "../Pages/Team.vue";
import Vendors from "../Pages/Vendors.vue";
import SignUp from "../Pages/SignUpVer2.vue";

const routes = [
  { path: "/", component: Home },
  { path: "/blog", component: Blog },
  { path: "/blog-post/:id", component: BlogPost },
  { path: "/brands", component: Brands },
  { path: "/brands/demo", component: BrandsDemoRequest },
  { path: "/brands-signup", component: BrandsSignup },
  { path: "/BrandFaq", component: BrandFaq },
  { path: "/brand-terms-and-conditions", component: BrandTc },
  { path: "/career", component: Career },
  { path: "/contact", component: Contact },
  { path: "/creators", component: Creators },
  { path: "/CreatorFaq", component: CreatorFaq },
  { path: "/creator-terms-and-conditions", component: CreatorTc },
  { path: "/creator-signup", component: CreatorSignup },
  { path: "/home", component: Home },
  { path: "/overview", component: Overview },
  { path: "/privacy", component: PrivacyTc },
  { path: "/Subscriptions", component: Subscriptions },
  { path: "/team", component: Team },
  { path: "/redemption", component: Vendors },
  { path: "/brand-signups", component: SignUp },
  { path: "/:pathMatch(.*)*", component: NotFound },
];

const router = createRouter({
  // scrollBehavior() {
  //   // always scroll to top
  //   return { top: 0 };
  // },
  history: createWebHistory(),
  // mode:'history',
  routes, // short for `routes: routes`
  // scrollBehavior() {
  //   document.getElementById("app").scrollIntoView({ behavior: "auto" });
  // },
});

router.beforeEach(function (to, from, next) {
  window.scrollTo(0, 0);
  next();
});

// glitch fix here
// Remove 'modal-open' class when leaving '/subscriptions' route
router.afterEach((to) => {
  // If we are not on the '/subscriptions' page, remove the modal-open class
  if (to.path !== "/subscriptions") {
    document.body.classList.remove("modal-open");
  }
});
export default router;
