// Components

// lANGUAGE
export const SET_LANGUAGES = (state, languages) => {
  state.languages = languages;
};
export const SWITCH_LANGUAGE = (state, langugeCode) => {
  state.activeLanguage = langugeCode;
};
export const SET_RTL = (state, istrue) => {
  state.isRtl = istrue;
};

// HEADER
export const GET_HEADER = (state, data) => {
  state.header = data;
};
// BRANDS
export const GET_BRANDS = (state, data) => {
  state.brandsSwiper = data;
};
// GET BRAND LOVE US
export const GET_BRAND_LOVE_US = (state, data) => {
  state.brandLoveUs = data;
};
// GET CREATOR LOVE US
export const GET_CREATOR_LOVE_US = (state, data) => {
  state.creatorLoveUs = data;
};
// FOOTER
export const GET_FOOTER = (state, data) => {
  state.footer = data;
};
// TEAM
// export const GET_TEAM = (state, data) => {
//   state.team = data;
// };
export const GET_DEPARTMENTS = (state, data) => {
  state.departments = data;
  if (data !== null) {
    state.activeDepartment = data[0];
  }
};
export const SET_ACTIVE_DEPARTMENT = (state, data) => {
  state.activeDepartment = data;
};
// BLOGS

export const SET_ACTIVE_BLOG = (state, data) => {
  state.activeBlogId = data;
};
export const SET_ALL_BLOG_TITLES = (state, data) => {
  state.blogTitles = data;
  //   state.activeDepartment = data[0];
};
export const SET_BLOGS = (state, data) => {
  state.blogs = data;
};
export const GET_CAREER = (state, data) => {
  state.careers = data;
};
export const GET_CONTACT_US = (state, data) => {
  state.contactUsPage = data;
};
export const GET_CAREER_PAGE = (state, data) => {
  state.careerPage = data;
};
export const SET_BLOG_DETAILS = (state, data) => {
  state.blogDetails = data;
};
export const SET_BLOGS_PAGINATION = (state, data) => {
  state.blogPagination = data;
};
//  PAGES

export const GET_Home_PAGE = (state, data) => {
  state.homePage = data;
};
export const GET_PAGE_NOT_FOUND = (state, data) => {
  state.notFound = data;
};
export const GET_OVERVIEW_PAGE = (state, data) => {
  state.overviewPage = data;
};
export const GET_SUBSCRIPTION_PAGE = (state, data) => {
  state.subscriptionPage = data;
};
export const GET_SUBSCRIPTION_PLANS = (state, data) => {
  state.subscriptionPlans = data;
};
export const GET_SUBSCRIPTION_DURATIONS = (state, data) => {
  state.subscriptionDurations = data;
};
export const GET_CREATOR_SIGNUP_PAGE = (state, data) => {
  state.creatorSignupPage = data;
};
export const GET_BRAND_SIGNUP_PAGE = (state, data) => {
  state.brandSignupPage = data;
};
export const GET_BLOG_PAGE = (state, data) => {
  state.blogPage = data;
};
export const GET_BLOG_DETAILS_PAGE = (state, data) => {
  state.blogDetailsPage = data;
};
export const GET_CREATOR_PAGE = (state, data) => {
  state.creatorPage = data;
};
export const GET_BRAND_PAGE = (state, data) => {
  state.brandPage = data;
};
/* new form */
export const GET_NEW_SIGNUP_FORM = (state, data) => {
  state.newSignUpForm = data;
};
export const GET_CREATOR_TC = (state, data) => {
  state.TcPrivacy = data;
};
export const GET_ERROR_MESSAGES = (state, data) => {
  state.errorMessges = data;
};
// FAQ
export const GET_BRANDS_FAQ_PAGE = (state, data) => {
  state.brandFaqPage = data;
};
export const GET_CREATOR_FAQ_PAGE = (state, data) => {
  state.creatorFaqPage = data;
};
export const GET_Brand_FAQ = (state, data) => {
  state.brandsFaq = data;
};
export const GET_Creator_FAQ = (state, data) => {
  state.creatorFaq = data;
};

// TEAM
export const GET_TEAM_PAGE = (state, data) => {
  state.teamPage = data;
};
export const SET_LOCATIONS = (state, data) => {
  state.locations = data;
};
export const SET_ACTIVE_JOB = (state, data) => {
  state.activeJob = data;
};

export const SET_NATIONALITIES = (state, data) => {
  state.nationalities = data;
};

export const SET_COUNTRIES = (state, data) => {
  state.countries = data;
};
export const SET_UTM = (state, data) => {
  state.utm = data;
};

export const GET_GEN_SETTINGS = (state, data) => {
  state.genSettings = data;
};
