<template>
  <div
    class="ts-team-card ts-shadow-1 overflow-hidden text-decoration-none d-block"
  >
    <div class="ts-team-card__header">
      <img
        class="ts-team-card__img w-100"
        :src="$store.state.baseUrl + imageUrl"
        :alt="imageUrl.alternativeText"
      />
    </div>
    <div class="ts-team-card__body text-center ts-text-gray-3">
      <h6 class="ts-team-card__subtitle fw-semibold text-center">{{ name }}</h6>
      <h6 class="ts-team-card__subtitle mb-0 text-center">{{ designation }}</h6>
    </div>
  </div>
</template>
<script>
export default {
  name: "TeamCard",
  props: {
    name: String,
    designation: String,
    imageUrl: String,
    linkSrc: String,
  },
};
</script>
<style lang="scss" scoped>
.ts-team-card {
  background-color: white;
  border-radius: 1.5625rem;

  &__header {
    background-color: #9b9599;
  }
  &__header,
  &__img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  &__body {
    padding-block: 0.8125rem 1rem;
    @media (max-width: 991px) {
      padding-block: 0.875rem 1.1875rem;
    }
  }
  &__title {
    font-size: 18px;
    @media (max-width: 991px) {
      font-size: 1.5625rem;
    }
  }
  &__subtitle {
    font-size: 15px;
    @media (max-width: 991px) {
      font-size: 18px;
    }
  }
}
</style>
