<template>
  <section class="vendors-wrapper">
    <h1 class="vendors-title">
      Vendor <span class="green-font-color">OTP</span> Guide
    </h1>
    <section class="vendors-step column-reversed">
      <div class="vendors-step-details">
        <div class="vendors-mobile-title display-lg-none">
          <h2 class="fw-semibold">
            <span class="green-font-color">01.</span> Request the Email from the
            Creator
          </h2>
        </div>
        <h2 class="green-font-color fw-semibold display-mobile-none">01</h2>

        <h3 class="fw-semibold display-mobile-none">
          Request the Email from the Creator
        </h3>

        <p class="f-poppins-regular">
          When the creator arrives, ask them to present the email they received
          from AList. Locate the
          <span href="#" class="green-font-color underline">link</span> and
          click on it to access the voucher validation page.
        </p>
      </div>
      <picture class="margin-right-25">
        <source
          srcset="/images/Vendors/mobile/1.jpg"
          media="(max-width: 330px)"
        />

        <source
          srcset="/images/Vendors/desktop/1.jpg"
          media="(min-width: 333px)"
        />

        <img src="/images/Vendors/desktop/1.jpg" alt="Responsive image" />
      </picture>
    </section>
    <section class="vendors-step column">
      <picture>
        <source
          srcset="/images/Vendors/mobile/3.jpg"
          media="(max-width: 330px)"
        />

        <source
          srcset="/images/Vendors/desktop/3.jpg"
          media="(min-width: 333px)"
        />

        <img src="/images/Vendors/desktop/3.jpg" alt="Responsive image" />
      </picture>

      <div class="vendors-step-details">
        <div class="vendors-mobile-title display-lg-none">
          <h2 class="fw-semibold">
            <span class="green-font-color">02.</span> Enter the Venue Code
          </h2>
        </div>
        <h2 class="green-font-color fw-semibold display-mobile-none">02</h2>

        <h3 class="fw-semibold display-mobile-none">Enter the Venue Code</h3>

        <p class="f-poppins-regular">
          Input your 4-digit venue code on the creator's phone when prompted.
        </p>
      </div>
    </section>
    <section class="vendors-step column-reversed">
      <div class="vendors-step-details">
        <div class="vendors-mobile-title display-lg-none">
          <h2 class="fw-semibold">
            <span class="green-font-color">03.</span> Confirm Voucher Redemption
          </h2>
        </div>
        <h2 class="green-font-color fw-semibold display-mobile-none">03</h2>

        <h3 class="fw-semibold display-mobile-none">
          Confirm Voucher Redemption
        </h3>

        <p class="f-poppins-regular">
          Confirm the voucher and OTP to complete the process. A
          <span class="f-poppins-semiBold">"Successfully Redeemed"</span>
          message will be displayed, confirming the voucher redemption.
        </p>
      </div>
      <picture>
        <source
          srcset="/images/Vendors/mobile/4.jpg"
          media="(max-width: 330px)"
        />

        <source
          srcset="/images/Vendors/desktop/4.jpg"
          media="(min-width: 333px)"
        />

        <img src="/images/Vendors/desktop/4.jpg" alt="Responsive image" />
      </picture>
    </section>
  </section>
</template>

<script>
export default {
  name: "VendorsPage",
};
</script>

<style>
.vendors-wrapper {
  max-width: 76rem;
  padding: 0 1.75rem;
  margin: 15rem auto 9rem auto;
}

.vendors-title {
  font-size: clamp(2rem, 4vw + 1rem, 3.4375rem);
  color: #4d4d4d;
  font-weight: bold;
  margin: 0 auto 6.125rem auto;
  width: fit-content;
}

.vendors-step {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2.5rem;
}

.vendors-step-details {
  max-width: 40.28rem;
}

.vendors-step-details h2 {
  font-size: clamp(1.75rem, 2vw + 2rem, 3.8125rem);
  margin-bottom: -0.5rem;
}

.vendors-step-details h3 {
  color: #595959;
  font-size: 1.875rem;
  margin-bottom: 1.25rem;
}

.vendors-step-details p {
  color: #484848;
  font-size: 1.375rem;
}

@media (max-width: 768px) {
  .vendors-wrapper {
    margin: 12rem auto 7rem auto;
  }
  .display-mobile-none {
    display: none;
  }
  .vendors-wrapper {
    text-align: center;
    padding: 0 0.75rem;
  }
  .vendors-title {
    margin-bottom: 5rem;
  }
  .column-reversed {
    flex-direction: column-reverse;
  }
  .column {
    flex-direction: column;
  }
  .vendors-step picture img {
    margin-bottom: 1rem;
    width: 75%;
  }
  .vendors-step-details p {
    font-size: 1.125rem;
  }
  .vendors-mobile-title h2 {
    font-size: 1.75rem;
    color: #595959;
    margin-bottom: 1.375rem;
  }
}
/* utilities */
@media (min-width: 769px) {
  .display-lg-none {
    display: none;
  }
  .margin-right-25{
    margin-right: 1.563rem;
  }
}
.green-font-color {
  color: #00a4b6;
}

.underline {
  text-decoration: underline;
}
</style>
