<template>
  <a
    v-if="isExternalLink(toSrc)"
    class="dropdown-item"
    target="_blank"
    rel="noopener noreferrer"
    :href="toSrc"
  >
    {{ text }}
  </a>
  <!-- If it's not an external link, use Vue's router-link -->
  <router-link v-else class="dropdown-item" :to="'/' + toSrc">
    {{ text }}</router-link
  >
</template>

<script>
export default {
  props: {
    toSrc: String,
    text: String,
  },
  methods: {
    isExternalLink(url) {
      return url.startsWith("https://") || url.startsWith("http://");
    },
  },
};
</script>
