<template>
  <section
    class="ts-hero d-flex align-items-center"
    v-if="$store.state.creatorPage"
  >
    <div class="w-100">
      <div class="ts-hero-bg" :style="elementStyles"></div>
      <div class="ts-container-fluid container-fluid mb-4 mb-lg-0">
        <div class="ts-hero__content text-center text-lg-start">
          <h1 class="ts-fs-1 ts-text-gray-5 fw-bold mb-06 mb-lg-07">
            {{ $store.state.creatorPage.Banner.title }}
          </h1>
          <p
            class="ts-hero__desc ts-text-gray-1 ts-fs-5 fw-light mb-07-ii ms-rtl-auto"
          >
            {{ $store.state.creatorPage.Banner.description }}
          </p>
          <router-link
            class="ts-btn ts-btn--sm-lg ts-btn-primary rounded-pill text-center"
            :to="$store.state.creatorPage.Banner.Signup.to"
          >
            {{ $store.state.creatorPage.Banner.Signup.text }}
          </router-link>
        </div>
      </div>

      <img
        class="w-100 d-lg-none"
        src="/images/Creators/hero-bg-sm.png"
        alt="alternativeText"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "TsHero",

  data() {
    return {
      elementStyles: {
        "background-image": "none", // Default background image
      },
    };
  },

  methods: {
    calculateElementStyles() {
      // Check if the creatorPage exists in the store
      if (this.$store.state.creatorPage) {
        // Define your base styles
        let styles = {
          "background-image": `url('${
            this.$store.state.baseUrl +
            this.$store.state.creatorPage.Banner.bannerImages.mediumImage.data
              .attributes.url
          }')`,
        };
        // Add additional styles for the specified media query
        if (window.innerWidth >= 1920) {
          styles = {
            ...styles, // Keep the base styles
            "background-image": `url('${
              this.$store.state.baseUrl +
              this.$store.state.creatorPage.Banner.bannerImages.largeImage.data
                .attributes.url
            }')`,
          };
        }
        return styles;
      } else {
        // Fallback styles if creatorPage doesn't exist
        return {
          "background-image": "none", // Default background image
        };
      }
    },

    handleResize() {
      // Update the elementStyles when the window is resized
      this.elementStyles = this.calculateElementStyles();
    },
  },

  mounted() {
    // Initialize elementStyles
    this.handleResize();

    // Add an event listener for the window resize event
    window.addEventListener("resize", this.handleResize);
  },

  beforeUnmount() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener("resize", this.handleResize);
  },

  watch: {
    // Watch for changes in the creatorPage or baseUrl
    "$store.state.creatorPage": {
      handler() {
        this.elementStyles = this.calculateElementStyles();
      },
      deep: true,
    },
    "$store.state.baseUrl": function () {
      this.elementStyles = this.calculateElementStyles();
    },
  },
};
</script>

<style scoped lang="scss">
.ts-hero {
  position: relative;
  isolation: isolate;
  @media (max-width: 992px) {
    background-color: #f9f9f9 !important;
  }
  .ts-hero-bg {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;

    background-size: 70% auto;
    background-position: 120% bottom !important;
    z-index: -1;

    // @media (max-width: 1920px) {
    //   background-image: url("../../../public/images/Creators/hero-bg.png");
    // }
    @media (max-width: 992px) {
      background-color: #f9f9f9 !important;
    }
    @media (max-width: 575px) {
      display: none;
      padding: 0;
    }
    // @media (min-width: 1921px) {
    //   background-image: url("../../../public/images/Creators/hero-bg-xl.png");
    // }
  }

  @media (max-width: 991px) {
    padding-bottom: clamp(280px, 67vw, 332px);
    background-size: 70% auto !important;

    .ts-btn {
      width: 100%;
      max-width: 300px;
    }
  }

  @media (max-width: 991px) {
    padding-bottom: 0;
  }
  .ts-hero-bg {
    @media (max-width: 767px) {
      background-size: 60% auto !important;
      padding-bottom: clamp(280px, 75vw, 340px);
      background-position: 100% bottom !important;
    }
    @media (max-width: 740px) {
      background-size: 67% auto !important;
    }
    @media (max-width: 670px) {
      background-size: 72% auto !important;
    }
    @media (max-width: 620px) {
      background-size: 80% auto !important;
    }
    @media (max-width: 540px) {
      background-size: 105% auto !important;
      left: 65px;
    }
    @media (max-width: 470px) {
      left: 70px;
      background-size: 110% auto !important;
    }
    @media (max-width: 430px) {
      left: 15px;
      background-size: 90% auto !important;
    }
    @media (max-width: 414px) {
      left: 7px;
      background-size: 87% auto !important;
    }

    @media (min-width: 1920px) {
      background-size: 70% auto !important;
      background-position: 115% bottom !important;
    }
    @media (min-width: 2400px) {
      background-size: 65% auto !important;
      background-position: 115% bottom !important;
    }
    @media (min-width: 2600px) {
      background-size: 60% auto !important;
      background-position: 115% bottom !important;
    }
    @media (min-width: 2800px) {
      background-size: 55% auto !important;
      background-position: 100% bottom !important;
    }
    @media (min-width: 3200px) {
      background-size: 50% auto !important;
      background-position: 100% bottom !important;
    }
  }

  @media (min-width: 992px) {
    &__content {
      // max-width: 590px;
      max-width: 640px;
    }

    &__desc {
      max-width: 540px;
    }
  }

  @media (max-width: 420px) {
    &__content {
      max-width: 330px;
      margin: auto;
    }
  }
}
</style>
